<script setup>
import c3api from '@/c3api';
import useClients from '@/hooks/useClients';
import ClientLayout from '@/layouts/ClientLayout.vue';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

const { client, fetchClient, rates, fetchRates } = useClients();
const route = useRoute();
const toast = useToast();
const rateData = ref(null);

onMounted(async () => {
    await fetchClient(route.params.id);
    await fetchRates(route.params.id);

    convertResponseData();
})

const convertResponseData = () => {
    rateData.value = rates.value.reduce((acc, item) => {
        if (!acc[item.category]) {
            acc[item.category] = [];
        }
        acc[item.category].push({id: item.id, code: item.code, name: item.name, cost: item.cost, category: item.category});
        return acc;
    }, {});
}

const save = async () => {
    // get the rates that need to be updated
    const updatedRates = rates.value
                            .filter(rate => rateData.value[rate.category].find(rateData => rateData.id === rate.id)?.cost !== rate.cost)
                            .map(rate => ({ id: rate.id, rate: rateData.value[rate.category].find(rateData => rateData.id === rate.id).cost }));

    if (updatedRates.length == 0){
        toast.error("Please update at least one rate.");
        return
    }
    
    try {
        const response = await c3api.patch(`/clients/${route.params.id}/rates`, {rates: updatedRates});
        toast.success("Rates Updated Successfully.");
        await fetchRates(route.params.id);
        convertResponseData();
    } catch (error) {
        toast.error(error.response.data.data?.join(". ")); 
    }
}

</script>

<template>
    <ClientLayout :client="client">
        <div class="mx-4 mt-2">
            <div class="text-h4 mb-2">Billing Rates</div>

            <v-card class="text-center mt-4 py-4" v-if="!rateData">
                <v-progress-circular
                    :width="8"
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-card>

            <v-card v-else v-for="rate in rateData" class="mt-4">
                <v-card-title class="bg-surface-light">{{ rate[0].category }}</v-card-title>
                <v-divider/>
                <div  v-for="cat in rate">
                    <v-row class="pa-2 pl-4 align-center">
                        <v-col cols="3">
                            {{cat.code}}
                        </v-col>
                        <v-col cols="4">
                            {{cat.name}}
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                v-model="cat.cost"
                                variant="outlined"
                                hide-details
                                density="compact"
                                type="number"
                            />
                        </v-col>
                    </v-row>
                    <v-divider/>
                </div>
            </v-card>

            <div class="d-flex justify-end mt-5">
                <v-btn 
                    size="large" 
                    variant="flat" 
                    color="primary"
                    @click="save"
                    >
                    Save
                </v-btn>
            </div>
        </div>
    </ClientLayout>
</template>

<style scoped>
.text-h4 {
    /*needs to be inside scope different font used in other components */
    font-family: 'Source Sans 3', 'sans-serif' !important;
}

</style>